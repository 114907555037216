import React, { Component } from 'react'
import Helmet from 'react-helmet'
import IframeForm from './iframe-form'

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      src: 'https://meci.mx/email-form/contacto.php',
    }
  }

  render() {
    return (
      <section id='hero-form'>
        <div className='col-12 my-5'>
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
              async
              defer
            ></script>
          </Helmet>
          <div className='d-flex flex-wrap align-items-center col-12 col-md-8 mx-auto p-3'>
            <div className='mx-auto'>
              <p className='fs-2 fw-bold text-center text-site-orange'>
                PONTE EN CONTACTO
              </p>
              <p className='text-center'>
                Si tienes dudas acerca de algún producto o equipo, ponte en
                contacto con uno de nuestros asesores. Es totalmente sin
                compromiso y puedes asegurarte de que obtendrás la información
                que necesitas.
              </p>
            </div>
            <IframeForm source={this.state.src} />
          </div>
        </div>
      </section>
    )
  }
}

export default ContactForm

// export default function ContactForm() {
//   return (
//     <section id='hero-form'>
//       <div className='col-12 my-5'>
//         <Helmet>
//           <script
//             src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
//             async
//             defer
//           ></script>
//         </Helmet>
//         <div className='d-flex flex-wrap align-items-center col-12 col-md-8 mx-auto p-3'>
//           <div className='mx-auto'>
//             <p className='fs-2 fw-bold text-center text-site-orange'>
//               PONTE EN CONTACTO
//             </p>
//             <p className='text-center'>
//               Si tienes dudas acerca de algún producto o equipo, ponte en
//               contacto con uno de nuestros asesores. Es totalmente sin
//               compromiso y puedes asegurarte de que obtendrás la información que
//               necesitas.
//             </p>
//           </div>
//           <IframeForm />
//           {/* iframe ends */}
//         </div>
//       </div>
//     </section>
//   )
// }
