// Step 1: Import React. This lets you use JSX inside your .js file.
import * as React from 'react'

/* Step 2: Define your component. Note that your
component name should start with a capital letter. */
const IframeForm = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>
  }
  const src = source
  return (
    // basic bootstrap classes. you can change with yours.
    <div className='col-12'>
      <div className='w-100'>
        <iframe
          title='Contact form'
          className='col-12'
          style={{ height: 30 + 'rem' }}
          src={src}
        ></iframe>
      </div>
    </div>
  )
}

/* Step 3: Export your component so it
can be used by other parts of your app. */
export default IframeForm
