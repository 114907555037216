import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import ContactForm from '../components/contact-form'
import Layout from '../components/layout'
import Seo from '../components/seo'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title='Mercantil de Equipos Contra Incendio' />
      {/* main hero */}
      <div className='div-h100 d-flex flex-column flex-md-row justify-content-around align-items-center bg-smoke px-3'>
        <div className='col-12 col-md-6 mx-1'>
          <div
            id='carouselExampleInterval'
            className='carousel slide'
            data-bs-ride='carousel'
          >
            <div className='carousel-inner'>
              <div className='carousel-item active' data-bs-interval='3000'>
                <StaticImage
                  alt='Extintor MECI PQ9'
                  src='../images/pqs9_front.png'
                  layout='fullWidth'
                />
              </div>
              <div className='carousel-item' data-bs-interval='3000'>
                <StaticImage
                  alt='Extintor MECI PQ9'
                  src='../images/pqs2kg_front.png'
                  layout='fullWidth'
                />
              </div>
              <div className='carousel-item' data-bs-interval='3000'>
                <StaticImage
                  alt='Extintor MECI PQ9'
                  src='../images/gabinete_front.png'
                  layout='fullWidth'
                />
              </div>
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleInterval'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleInterval'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
        <div className='col-12 col-md-6 ps-3 pe-5 py-4 bg-black-alpha mx-1 my-2'>
          <h1 className='text-site-orange fw-bold fs-1 lh-1'>
            EQUIPO DE PROTECCIÓN CONTRA INCENDIOS
          </h1>
          <p className='text-site-orange'>
            Los extintores de incendio son invisibles la mayor parte del tiempo,
            hasta que una emergencia aparece. Es de suma importancia contar con
            algún tipo de protección contra el fuego en nuestro hogar o negocio
            así como darles el mantenimiento adecuado para asegurar su buen
            funcionamiento.
          </p>
          <Link to='/portatiles' className='btn btn-orange btn-lg'>
            VER PORTÁTILES
          </Link>
          <Link
            to='/moviles'
            className='btn btn-orange btn-lg ms-md-2 mt-2 mt-md-0'
          >
            VER UNIDADES MÓVILES
          </Link>
        </div>
      </div>
      {/* multi-section */}
      <div className='container my-4'>
        {/* a module */}
        <div className='col-12 col-md-10 d-flex flex-md-row flex-column justify-content-between align-items-center mx-auto'>
          <div className='col-12 col-md-6'>
            <StaticImage
              src='../images/recarga-agentes-limpios.jpg'
              alt='Servicio de recarga de agentes limpios en el taller de MECI'
              layout='constrained'
              width={600}
              className='float-md-start float-none m-2'
            />
          </div>
          <div className='col-12 col-md-6 p-3'>
            <p className='lead text-site-orange fw-bold fs-3 lh-1'>
              SERVICIOs DE RECARGA DE AGENTES LIMPIOS
            </p>
            <p>
              Recargamos sus equipos que requieren de FM-200, Novec 1230, Ecaro,
              FE-36, CO&sup2;.
              <br />
              Llama sin compromiso para una cotización!
            </p>
            <Link to='#hero-form' className='btn btn-orange btn-lg'>
              SOLICITAR COTIZACIÓN
            </Link>
          </div>
        </div>
        {/* a module */}
        <div className='col-12 col-md-10 d-flex flex-md-row flex-column justify-content-between align-items-center mx-auto my-3'>
          <div className='col-12 col-md-7 p-3 order-md-0 order-1'>
            <p className='lead text-site-orange fw-bold fs-3 lh-1'>
              SERVICIOS PROFESIONALES DE MANTENIMIENTO Y RECARGA DE EXTINTORES
            </p>
            <p>
              Servicio de mantenimiento y recarga de extintores de PQS, Agua,
              Co2, Clase K, Clase D, Water Mist.
              <br />
              Contamos con refacciones para extintores portátiles, unidades
              móviles y para sistemas contra incendio de distintas marcas
              internacionales. Para mayor información,{' '}
              <Link to='#hero-form' className='orange-link'>
                puedes ponerte en contacto
              </Link>{' '}
              con nosotros para agendar el servicio.
            </p>
            <Link to='#hero-form' className='btn btn-orange btn-lg'>
              SOLICITAR INFORMACIÓN
            </Link>
          </div>
          <div className='col-12 col-md-5'>
            <StaticImage
              src='../images/extinguidores-rojos.jpg'
              alt='Muchos extintores rojos formados'
              layout='fullWidth'
            />
          </div>
        </div>
      </div>
      {/* multi-section */}
      <div className='container my-4'>
        {/* a module */}
        <div className='col-12 col-md-10 d-flex flex-md-row flex-column justify-content-between align-items-center mx-auto'>
          <div className='col-12 col-md-4'>
            <StaticImage
              src='../images/extinguidor-columna.jpg'
              alt='Extintor en un pasillo de una oficina'
              layout='fullWidth'
            />
          </div>
          <div className='col-12 col-md-8 p-3'>
            <p className='lead text-site-orange fw-bold fs-3 lh-1'>
              ¿TUS OFICINAS O NEGOCIO ESTÁN PROTEGIDOS?
            </p>
            <p>
              Confía en un sistema contra incendio para la protección de tu
              negocio u oficina, contamos con diferentes marcas y agentes de
              extinción para cubrir las necesidades especiales de las áreas que
              se deben de proteger.{' '}
              <Link to='#hero-form' className='orange-link'>
                Puedes contactarnos
              </Link>{' '}
              para obtener orientación personalizada.
            </p>
            <Link to='/sistemas' className='btn btn-orange btn-lg'>
              VER SISTEMAS
            </Link>
          </div>
        </div>
        {/* a module */}
        <div className='col-12 col-md-10 d-flex flex-md-row flex-column justify-content-between align-items-center mx-auto my-3'>
          <div className='col-12 col-md-5 p-3 order-md-0 order-1'>
            <p className='lead text-site-orange fw-bold fs-3 lh-1'>
              INSTALA GABINETES PARA EXTINTORES EN TODOS TUS ESPACIOS
            </p>
            <p>
              Protege tu equipo contra incendio mientras lo haces visible para
              los usuarios en caso de alguna emergencia de incendio. De acuerdo
              a la arquitectura de tus espacios y al tipo de extintor, tenemos
              múltiples opciones para ofrecer,{' '}
              <Link to='#hero-form' className='orange-link'>
                puedes ponerte en contacto
              </Link>{' '}
              con nosotros para una mejor selección.
            </p>
            <Link to='/gabinetes' className='btn btn-orange btn-lg'>
              VER GABINETES
            </Link>
          </div>
          <div className='col-12 col-md-7'>
            <StaticImage
              src='../images/gabinetes-pared-amarilla.jpg'
              alt='Gabinetes instalados sobre una pared amarilla'
              layout='fullWidth'
            />
          </div>
        </div>
      </div>
      {/* double module */}
      <div className='col-12 col-md-10 d-flex flex-column flex-md-row justify-content-around align-items-start mx-auto px-md-5 px-1'>
        {/* mod 1 */}
        <div className='d-flex flex-column col-12 col-md-4 p-4'>
          <StaticImage
            src='../images/taller/taller-11.jpg'
            alt='Extintor en un pasillo de una oficina'
            layout='constrained'
            width={600}
            className='float-md-start float-none m-2'
          />
          <p className='mt-3 text-site-orange fw-bold fs-4 lh-1'>
            CONOCE NUESTRO TALLER
          </p>
          <p>
            Contamos con un taller muy completo para la recarga de extintores y
            de tanques de sistemas de agentes limpios.{' '}
            <Link to='#hero-form' className='orange-link'>
              Puedes contactarnos
            </Link>{' '}
            para obtener orientación personalizada.
          </p>
          <Link to='/el-taller' className='btn btn-orange'>
            VISTAR EL TALLER DE MECI
          </Link>
        </div>
        {/* mod 2 */}
        <div className='d-flex flex-column col-12 col-md-4 p-4'>
          <StaticImage
            src='../images/extinguidores-pqs-meci.jpg'
            alt='extintores PQS de MECI'
            layout='constrained'
            width={600}
            className='float-md-start float-none m-2'
          />
          <p className='mt-3 text-site-orange fw-bold fs-4 lh-1'>
            TEN SIEMPRE A LA MANO UN EXTINTOR
          </p>
          <p>
            Ya sea en el hogar, en la oficina, en el taller ó en cualquier otro
            lugar, la seguridad contra el fuego es una prioridad. Tener la
            capacidad para detener el primer avance de un incendio es de suma
            importancia para evitar pérdidas materiales y personales.
          </p>
          <Link to='/portatiles' className='btn btn-orange'>
            VER PORTÁTILES NACIONALES
          </Link>
        </div>
        {/* mod 3 */}
        <div className='d-flex flex-column col-12 col-md-4 p-4'>
          <StaticImage
            src='../images/equipo-recarga.jpg'
            alt='Equipo de recarga de extintores Getz'
            layout='constrained'
            width={600}
            className='float-md-start float-none m-2'
          />
          <p className='mt-3 text-site-orange fw-bold fs-4 lh-1'>
            EQUIPOS PARA TALLER DE RECARGA
          </p>
          <p>
            Venta de equipo profesional para taller de recarga, tolvas, prensas
            neumáticas, básculas y equipos para montar en camionetas.
            <br />
            <Link to='#hero-form' className='orange-link'>
              Ponte en contacto
            </Link>{' '}
            para mayor información acerca de estos equipos.
          </p>
          <Link to='/equipo-taller' className='btn btn-orange'>
            VER EQUIPOS PARA TALLER
          </Link>
        </div>
      </div>
      {/* contact form */}
      <ContactForm />
    </Layout>
  )
}

export default IndexPage
